import React, { useState, useEffect } from "react";
import Link from "next/link";
import { Tab, Tabs, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Meta from "../../components/Meta";
import { useRouter } from 'next/router';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { appFirst } from '../../firebase';

const Login = () => {
    const router = useRouter();
    const auth = getAuth(appFirst);

    // Charger dynamiquement le script Google Ads et initialiser les annonces
    useEffect(() => {
        const scriptId = "google-ads-script";
        if (!document.getElementById(scriptId)) {
            const script = document.createElement('script');
            script.id = scriptId;
            script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8097312950095161";
            script.async = true;
            script.crossOrigin = 'anonymous';
            document.body.appendChild(script);

            script.onload = () => {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            };
        }
    }, []);



    return (
        <div>
            <Meta title="Login" />
            <section className="relative h-screen">
                <div className="lg:flex lg:h-full">
                    <div className="relative text-center lg:w-1/2">
                        <img src="/images/login.jpg" alt="login" className="absolute h-full w-full object-cover"/>
                        <Link href="/">
                            <a className="relative inline-block py-36">
                                <img src="/images/logo_white.png" className="inline-block max-h-7" alt="My French"/>
                            </a>
                        </Link>
                    </div>
                    <div className="relative flex items-center justify-center p-[10%] lg:w-1/2">
                        <div className="w-full max-w-[25.625rem] text-center">
                            <h1 className="text-jacarta-700 font-display mb-6 text-4xl dark:text-white">
                                Sign in
                            </h1>
                            <p className="dark:text-jacarta-300 mb-10 text-lg leading-normal">
                                Coming soon 🥐
                            </p>
                            <Tabs className="tabs">
                                <TabPanel>

                                    {/* Emplacement pour l'annonce Google Ads */}
                                    <ins className="adsbygoogle"
                                         style={{ display: "block", textAlign: "center" }}
                                         data-ad-layout="in-article"
                                         data-ad-format="fluid"
                                         data-ad-client="ca-pub-8097312950095161"
                                         data-ad-slot="9273945438"></ins>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Login;
